<template>
  <div class="upgrade-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="版本名称：">
        <el-input v-model="searchForm.versionName" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="版本编码：">
        <el-input v-model.number="searchForm.versionNo" placeholder="请输入编码"></el-input>
      </el-form-item>
      <el-form-item label="适用柜机：">
        <Select clearable placeholder="请选择柜机" v-model="searchForm.applyType" :list="applyList"></Select>
      </el-form-item>
      <el-form-item label="升级应用：">
        <Select clearable placeholder="请选择应用" v-model="searchForm.upgradeApplyCode" :list="upgradeList"></Select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button type="success" size="small" @click="newlyAdded('add')">新增</el-button>
    </el-row>
    <Table
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
      <!-- <template #url="{ originScope }">
        <span>{{originScope.row.url}}</span>
        <el-tooltip class="item" effect="dark" placement="top-start">
          <div slot="content">
           {{originScope.row.url}}
          </div>
        </el-tooltip>
      </template> -->
      <el-table-column slot="operate" label="操作" width="160" fixed="right">
        <template #default="scope">
          <el-button
            type="text"
            size="small"
            :disabled="scope.row.editFlag === false"
            :style="{ color: scope.row.editFlag === false ? '#808080' : '#77b922' }"
            @click="handleEdit(scope.row, 'edit')"
            >编辑</el-button
          >
          <el-button style="color: #77b922" type="text" size="small" @click="handleSeeList(scope.row)"
            >柜机列表</el-button
          >
        </template>
      </el-table-column>
    </Table>

    <!-- 新增 & 编辑 -->
    <Dialog width="576px" :title="dlogstatusTitle" :visible="visible" @close="closeDialog">
      <div class="equipment-add-wrap">
        <el-form
          size="small"
          ref="ruleForm"
          class="search-pannel custom-form"
          label-width="110px"
          label-position="left"
          :rules="rules"
          :model="form"
        >
          <el-form-item label="版本编码：" prop="versionNo">
            <el-input
              type="number"
              placeholder="请输入版本编码（纯数字）"
              oninput="if (value.length>5) value=value.slice(0,20)"
              onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
              v-model.number="form.versionNo"
              :disabled="isEdit"
            ></el-input>
          </el-form-item>
          <el-form-item label="升级应用：" prop="upgradeApplyCode">
            <Select
              clearable
              placeholder="请选择升级应用"
              :disabled="isEdit"
              v-model="form.upgradeApplyCode"
              :list="upgradeList"
              @change="handlePublicSelect($event, 'upgrade')"
            ></Select>
          </el-form-item>
          <el-form-item label="版本名称：" prop="versionName">
            <el-input
              placeholder="请输入版本名称"
              v-model="form.versionName"
              oninput="if (value.length>5) value=value.slice(0,30)"
            ></el-input>
          </el-form-item>
          <el-form-item label="升级包URL：" prop="url">
            <el-input
              placeholder="请输入URL"
              v-model="form.url"
              oninput="if (value.length>5) value=value.slice(0,255)"
            ></el-input>
          </el-form-item>
          <el-form-item label="适用柜机：" prop="applyType">
            <Select
              multiple
              clearable
              placeholder="请选择适用柜机"
              v-model="form.applyType"
              :list="applyList"
              @change="handlePublicSelect($event, 'apply')"
            ></Select>
          </el-form-item>
          <el-form-item label="MD5值：" prop="md5">
            <el-input
              placeholder="请输入MD5值"
              v-model="form.md5"
              oninput="if (value.length>5) value=value.slice(0,100)"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-row style="margin-top: 42px" type="flex" justify="end">
          <el-button @click="visible = false" size="small">返 回</el-button>
          <el-button type="success" @click="submitNewEquipment" size="small">确定</el-button>
        </el-row>
      </div>
    </Dialog>

    <!-- 柜机列表 -->
    <Dialog width="978px" title="柜机列表" :visible="cabinetVisible" @close="closeDialogcabinet">
      <div class="cabinet-dialog-wrap">
        <el-form
          size="small"
          ref="cabinetRuleForm"
          class="search-pannel"
          label-position="left"
          :model="cabinetForm"
          @submit.native.prevent
        >
          <el-form-item label="柜机编码：">
            <el-input placeholder="请输入柜机编码" v-model="cabinetForm.lockerCode"></el-input>
          </el-form-item>
          <el-form-item label="所属企业：">
            <el-input placeholder="请输入所属企业" v-model="cabinetForm.customerName"></el-input>
          </el-form-item>
          <el-form-item label="所属国家：">
            <el-select clearable placeholder="请选择所属国家" v-model="cabinetForm.areaCode" @change="changeactType">
              <el-option
                v-for="item in areaCodeList"
                :key="item.areaCode"
                :label="item.areaName"
                :value="item.areaCode"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="升级时间：">
            <el-date-picker
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-model="updateDate"
              @change="monitorTime"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="success" size="small" native-type="submit" @click="onSearchCabinet">搜索</el-button>
            <el-button size="small" @click="onResetCabinet('form')">重置</el-button>
          </el-form-item>
        </el-form>
        <Table
          style="margin-top: 30px"
          :header-cell-style="{ background: '#F7F8FA ' }"
          :columns="columnsCabine"
          :data="tableCabinetData"
          :total="pageCabinetParams.total"
          :page="pageCabinetParams.pageNum"
          :limit="pageCabinetParams.pageSize"
          :hidden="!tableCabinetData.length"
          @pagination="updatePageCabinet"
        >
          <template #upgradeTime="{ originScope }">
            {{ originScope.row.upgradeZone }}
            <span style="margin-left: 5px">{{ originScope.row.upgradeTime }}</span>
          </template>
        </Table>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import Select from '@/components/Select'
import Dialog from '@/components/Dialog'
import { getVendorList } from '@/api/modules/device'
import { addVersion, editVersion, getVersionList, getcountryList, getLockerPageList } from '@/api/modules/operation'
export default {
  // 版本管理
  name: 'versionManagement',
  components: {
    Table,
    Select,
    Dialog
  },
  data() {
    return {
      searchForm: {
        versionName: '',
        versionNo: '',
        applyType: [],
        upgradeApplyCode: ''
      },
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      pageCabinetParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      columns: [
        { label: '版本编码', prop: 'versionNo' },
        { label: '升级应用', prop: 'upgradeApplyName' },
        { label: '版本名称', prop: 'versionName' },
        { label: '适用柜机', prop: 'applyName' },
        { label: '升级包URL', prop: 'url' },
        { label: 'MD5值', prop: 'md5' },
        { label: '创建时间', prop: 'createSysTm' }
      ],
      columnsCabine: [
        { label: '柜机编码', prop: 'lockerCode' },
        { label: '柜机类型', prop: 'lockerTypeName' },
        { label: '所属企业', prop: 'customerName' },
        { label: '所属国家', prop: 'areaName' },
        { label: '升级时间', prop: 'upgradeTime' }
      ],
      tableData: [],
      tableCabinetData: [],
      tableLoading: false,
      visible: false,
      cabinetVisible: false,
      dlogstatusTitle: '',
      form: {
        versionNo: '', // 版本编号
        upgradeApplyCode: '', // 升级应用code
        upgradeApplyName: '', // 升级应用名称
        versionName: '', // 版本名称
        applyType: [], // 适用柜机类型code
        applyName: [], // 适用柜机类型名称
        url: '', // 包全路径
        md5: '' // md5值
      },
      upgradeList: [], // 升级应用
      applyList: [], // 适用柜机
      areaCodeList: [], // 所属国家
      updateDate: [], // 升级时间
      cabinetForm: {
        lockerCode: '',
        customerName: '',
        areaCode: '',
        upgreadeStartTime: '',
        upgreadeEndTime: ''
      },
      dlogstatus: '',
      applyType: ''
    }
  },
  created() {
    this.onSearch(true)
    this.getSelecltDate()
  },
  computed: {
    rules() {
      // var confirmNumberRule = (rule, value, callback) => {
      //   const rules = /^\d+$/
      //   if (!rules.test(value)) {
      //     callback(new Error('请输入版本编码且只能为数字'))
      //   }
      //   callback()
      // }
      return {
        versionNo: [
          { required: true, message: '请输入版本编码', trigger: 'blur' }
          // { validator: confirmNumberRule, trigger: 'blur' }
        ],
        versionName: [
          { required: true, message: '请输入版本名称', trigger: 'blur' },
          { max: 30, message: '版本名称最长可支持输入30字', trigger: 'blur' }
        ],
        upgradeApplyCode: [{ required: true, message: '请选择升级应用', trigger: 'blur' }],
        url: [
          { required: true, message: '请输入升级包URL', trigger: 'blur' },
          { max: 255, message: '升级包URL最长可支持输入255字', trigger: 'blur' }
        ],
        applyType: [{ required: true, message: '请选择适用柜机', trigger: 'blur' }],
        md5: [
          { required: true, message: '请输入MD5值', trigger: 'blur' },
          { max: 100, message: 'MD5最长可支持输入100字', trigger: 'blur' }
        ]
      }
    },
    isEdit() {
      return this.dlogstatus === 'edit'
    }
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    getCabinetParams(flag) {
      const getParam = { ...this.cabinetForm }
      getParam.pageNum = flag ? 1 : this.pageCabinetParams.pageNum
      getParam.pageSize = this.pageCabinetParams.pageSize
      return getParam
    },
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      getVersionList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.pageParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onSearchCabinet(flag) {
      const data = {
        versionId: this.versionId,
        productCodeList: this.applyType.split(','),
        ...this.getCabinetParams(flag)
      }
      console.log(data)
      getLockerPageList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableCabinetData = records || []
          this.pageCabinetParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          // this.tableLoading = false
        })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.searchForm = {
        versionName: '',
        lockerSearch: '',
        upgradeTime: ''
      }
      this.onSearch()
    },
    onResetCabinet(formName) {
      this.cabinetForm = {
        lockerCode: '',
        customerName: '',
        areaCode: '',
        upgreadeStartTime: '',
        upgreadeEndTime: ''
      }
      this.onSearchCabinet()
    },
    // 编辑
    handleEdit(row, type) {
      this.dlogstatus = type
      this.dlogstatusTitle = '编辑'
      this.visible = true
      this.versionId = row.id
      this.getSelecltDate()
      this.form = { ...row, applyName: row.applyName.split(','), applyType: row.applyType.split(',') }
    },
    handleSeeList(row) {
      this.versionId = row.id
      this.cabinetVisible = true
      this.applyType = row.applyType
      this.getcountry()
      this.onSearchCabinet()
    },
    // 新增
    newlyAdded(type) {
      this.dlogstatusTitle = '新增'
      this.visible = true
      this.dlogstatus = type
      this.getSelecltDate()
    },
    getSelecltDate() {
      this.getVendor('upgradeList', 'upgradeApplication')
      this.getVendor('applyList', 'productType')
    },
    // 获取下拉列表
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        const { records } = res.data || []
        this[fn] = records || []
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    // 获取城市列表
    getcountry(fn, type) {
      getcountryList().then((res) => {
        this.areaCodeList = res.data || []
        this.areaCodeList.forEach((item) => {
          item.value = item.areaCode
          item.label = item.areaName
        })
      })
    },
    closeDialog() {
      this.visible = false
      this.cabinetVisible = false
      this.$refs.ruleForm.resetFields()
      this.form = {
        versionNo: '',
        versionName: '',
        applyType: [],
        applyName: [],
        url: '',
        upgradeApplyCode: '',
        upgradeApplyName: '',
        md5: ''
      }
      this.dlogstatus = ''
      // this.deviceCodeList = []
    },
    closeDialogcabinet() {
      this.cabinetVisible = false
      this.cabinetForm = {
        lockerCode: '',
        customerName: '',
        areaCode: '',
        upgreadeStartTime: '',
        upgreadeEndTime: ''
      }
    },
    submitNewEquipment() {
      const data = {
        ...this.form,
        applyName: this.form.applyName.join(),
        applyType: this.form.applyType.join()
      }
      this.$refs.ruleForm.validate(async(valid) => {
        if (!valid) return
        if (this.dlogstatus === 'add') {
          this.handleaddExit(addVersion, data)
        } else {
          this.handleaddExit(editVersion, data)
        }
      })
    },
    handleaddExit(fn, data) {
      fn(data).then((res) => {
        if (res.code === '000000000') {
          this.$message.success(res.msg)
          this.visible = false
          this.dlogstatus === 'add' ? this.onSearch(true) : this.onSearch(false)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 下拉设备所属
    handlePublicSelect(value, type) {
      // 升级应用
      if (type === 'upgrade') {
        const newarr = this.upgradeList.filter((item) => item.itemCode === this.form.upgradeApplyCode)
        this.form.upgradeApplyName = newarr[0].itemValue
      }
      // 适用柜机
      if (type === 'apply') {
        // const newarr = this.applyList.filter((item) => item.itemCode === this.form.applyType)
        // this.form.applyName = newarr[0].itemValue
        const newarr = this.applyList.filter((item) => {
          return value.includes(item.itemCode)
        })
        this.form.applyName = newarr.map((item) => item.itemValue)
      }
    },
    // 监听时间变化
    monitorTime(val) {
      const timedata = []
      val &&
        val.forEach((item) => {
          timedata.push(new Date(item))
        })
      const [start, end] = timedata
      this.cabinetForm.upgreadeStartTime = start
      this.cabinetForm.upgreadeEndTime = end
      this.onSearchCabinet()
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    updatePageCabinet(val) {
      if (val) {
        this.pageCabinetParams.pageNum = val.page
        this.pageCabinetParams.pageSize = val.limit
        this.onSearchCabinet()
      }
    },
    // 处理select
    changeactType(value) {
      this.cabinetForm.areaCode = value
      this.onSearchCabinet()
    }
  }
}
</script>

<style lang="scss" scoped>
.upgrade-wrap {
  padding: 24px;
}
.equipment-add-wrap {
  padding: 0 26px;
}
.custom-form{
  gap: 20px !important;
}
::v-deep .el-tag.el-tag--info {
  color: #85cf26;
  background: #fbfff0;
}
::v-deep .el-tag.el-tag--info .el-tag__close {
  color: #ffffff;
  background: #85cf26;
  margin-top: 1px;
}
::v-deep input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}
</style>
